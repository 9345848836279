import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/Layout';
import PageTitle from 'components/PageTitle';
import i18n from '../i18n';

function NotFound(props) {
  const meta = {
    title: i18n.t('PAGE_NOT_FOUND')
  };
  const { title, description } = props.url.query;
  return (
    <Layout {...props} meta={meta}>
      <div className="container text-center">
        <PageTitle>{title || meta.title}</PageTitle>
        <p>{description}</p>
      </div>
    </Layout>
  );
}

NotFound.propTypes = {
  url: PropTypes.shape({
    query: PropTypes.object
  })
};

export default NotFound;
