import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Layout from 'components/Layout';
import NotFound from 'components/NotFound';
import prismic from '../prismic';
import { fetchLanding } from 'actions/prismic';

class Landing extends Component {
  static async getInitialProps({ store, prismicRef, lang, query: { slug } }) {
    return store.dispatch(fetchLanding({ lang, slug, prismicRef }));
  }

  render() {
    const { landing, ...rest } = this.props;
    if (!landing) return <NotFound {...rest} />;
    const { ...other } = prismic.landing(landing);
    return <Layout hasPageNav {...other} {...rest} />;
  }
}

Landing.propTypes = {
  landing: PropTypes.object
};

export default connect(({ prismic }) => ({
  landing: prismic.landing
}))(Landing);
